<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Type <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
            <v-select
              outlined
              flat
              dense
              background-color="white"
              v-model="form.type"
              :items="listType"
              disabled
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Month <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
            <v-select
              outlined
              flat
              dense
              background-color="white"
              v-model.number="form.month"
              :items="listMonth"
              disabled
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Year</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6">
            {{ form.year }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Amount <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6">
            {{ formatPrice(form.amount) }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "detail-income-statement",
  props: {
    form: Object,
    listType: Array,
    listMonth: Array,
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
