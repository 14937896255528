<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Type <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
            <v-select
              outlined
              flat
              dense
              background-color="white"
              v-model="form.type"
              :items="listType"
              :rules="[v => !!v || 'Type is required']"
              disabled
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Month <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
            <v-select
              outlined
              flat
              dense
              background-color="white"
              v-model.number="form.month"
              :items="listMonth"
              :rules="[v => !!v || 'Month is required']"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Year <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
            <v-select
              outlined
              flat
              dense
              background-color="white"
              v-model.number="form.year"
              :items="listYear"
              :rules="[v => !!v || 'Year is required']"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="py-0">
          <v-col cols="3" sm="3" md="3" lg="3" class="py-0">
            <v-subheader>Amount <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="7" sm="7" md="6" lg="6" class="py-0">
            <v-text-field
              outlined
              flat
              dense
              background-color="white"
              type="number"
              v-model.number="form.amount"
              persistent-hint
              :hint="formatPrice(form.amount)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            sm="2"
            md="3"
            lg="3"
            v-if="form.year === new Date().getFullYear()"
            class="py-0"
          >
            <v-btn color="success" @click="getAmount"><v-icon>mdi-calculator</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "form-income-statement",
  props: {
    form: Object,
    listType: Array,
    listMonth: Array,
  },
  data() {
    return {
      valid: true,
    };
  },
  computed: {
    listYear() {
      const year = new Date().getFullYear();
      let array = [];
      for (let i = 0; i <= 5; i++) {
        array.push(year + i);
      }
      for (let i = 1; i <= 5; i++) {
        array.unshift(year - i);
      }
      return array;
    },
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("incomeStatement/create", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getAmount() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("incomeStatement/getAmount", this.form)
        .then(response => {
          this.form.amount = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
